import {defineStore} from 'pinia'

export const authStore = defineStore('auth', {
    state: () => ({
        user: undefined,
        isFormDisabled: false,
    }),

    actions: {
        async initAuth() {
            if (useCookie('token').value) {
                await useNuxtApp().$api('/user', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                })
                    .then(response => {
                        this.user = response.data
                    })
                    .catch(() => {
                        this.user = null
                    })
            } else {
                this.user = null
            }
        },

        updateUser(payload) {
            this.user = payload
        },

        async register(body) {
            return useNuxtApp().$api('/auth/register', {
                method: 'POST',
                body,
            })
                .then(async (response) => {
                    await useRouter().push(`/auth/verify/email?email=${body.email_address}`)
                })
                .catch((error) => {
                    useNuxtApp().$bus.$emit('flash', {
                        type: 'error',
                        title: error.response._data.message,
                    })
                })
        },

        async login(email_address, password) {
            return useNuxtApp().$api('/auth/login', {
                method: 'POST',
                body: {
                    email_address,
                    password,
                },
            })
                .then(async (response) => {
                    const time = 60 * 60 * 24 * 365
                    const token = useCookie('token', {
                        expires: new Date(Date.now() + time * 1000),
                    })
                    token.value = response.token

                    location.reload()
                })
                .catch((error) => {
                    useNuxtApp().$bus.$emit('flash', {
                        type: 'error',
                        title: error.response._data.message
                    })
                })
        },

        async logout() {
            return useNuxtApp().$api('/auth/logout', {
                method: 'POST',
            }).then(async () => {
                const token = useCookie('token')
                token.value = null

                await useRouter().push('/login')
            })
        },

        async requestPasswordReset(emailAddress) {
            this.isFormDisabled = true

            return useNuxtApp().$api(`/auth/forgot/password/${emailAddress}`, {
                method: 'POST',
            })
                .then(() => {
                    this.isFormDisabled = false

                    useNuxtApp().$bus.$emit('flash', {
                        type: 'success',
                        title: `A password reset link has been sent to your email address ${emailAddress}.`,
                    })
                })
                .catch(error => {
                    this.isFormDisabled = false

                    throw error
                })
        },

        async resetPassword(token, password) {
            this.isFormDisabled = true

            return useNuxtApp().$api(`/auth/reset/password/${token}`, {
                method: 'POST',
                body: {
                    password,
                },
            })
                .then(() => {
                    this.isFormDisabled = false

                    useNuxtApp().$bus.$emit('flash', {
                        type: 'success',
                        title: 'Your password has been updated successfully.',
                    })

                    useRouter().push('/login')
                })
                .catch(error => {
                    this.isFormDisabled = false

                    throw error
                })
        },

        async guessEmailProvider(emailAddress) {
            return useNuxtApp().$api(`/guess/email-provider/${emailAddress}`, {
                method: 'GET',
            })
        },
    },
})
