<template>
  <div v-click-outside="hideDropdownMenu" class="relative">
    <button class="flex items-start" @click="showDropdownMenu = !showDropdownMenu">
      <div class="group flex items-center whitespace-pre-wrap break-words">
        <div class="text-left flex-grow">
          <p class="text-xs font-medium text-gray-700 group-hover:text-gray-900">
            {{ user.name }}
          </p>

          <p class="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            {{ user.workspace.timezone }}{{ currentTime ? ` - ${currentTime}` : '' }}
          </p>
        </div>
      </div>
    </button>

    <inputs-drop-down-navigation v-if="showDropdownMenu" :menu-items="DROPDOWN_MENU_ITEMS">
      <template #append_menu_items>
        <actions-logout class="px-5 py-3 w-full"/>
      </template>
    </inputs-drop-down-navigation>
  </div>
</template>

<script setup>
import {authStore} from '~/stores/auth'
import {ACCOUNT, BILLING, BROWSER_EXTENSION, SECURITY, WORKSPACE} from '~/helpers/navigation-items.js'

const route = useRoute()

const user = computed(() => authStore().user)

const currentTime = ref(null)

const updateTime = () => {
  currentTime.value = new Date().toLocaleTimeString('en-US', {
    timeZone: user.value.workspace.timezone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: user.value.workspace.time_format == 12,
  })
}

onMounted(() => {
  updateTime()
  const timer = setInterval(updateTime, 1000)
  onUnmounted(() => clearInterval(timer))
})

const DROPDOWN_MENU_ITEMS = [WORKSPACE, ACCOUNT, SECURITY, BILLING, BROWSER_EXTENSION]

const showDropdownMenu = ref(false)

function hideDropdownMenu() {
  showDropdownMenu.value = false
}

watch(() => route.path, () => hideDropdownMenu())
</script>
